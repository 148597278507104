<template>
  <div class="grid h-screen" style="grid-template-rows: 55px 1fr 55px;">
    <header class="border-b bg-gray-100">
      <h2 class="text-center h-full uppercase font-semibold py-4">Shared Lists</h2>
    </header>
    <main class="overflow-auto pb-10">
      <div class="flex items-center justify-center">
        <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
          <ListCard v-for="item in sharedLists" :key="item.id" :sharedList="item" />
        </div>
      </div>
    </main>
    <AppFooter/>
  </div>
</template>


<script>
import AppFooter from '@/components/AppFooter'
import ListCard from '@/components/ListCard'
import { mapState } from 'vuex'

export default {
  name: 'SharedLists',
  components: {
    AppFooter,
    ListCard
  },
  computed: {
    ...mapState(['sharedLists']),
  },
  mounted () {
    this.$store.dispatch('fetchSharedLists')
  }
}
</script>

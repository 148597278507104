<template>
  <div class="relative bg-white py-4 px-6 rounded-3xl w-64 my-4 shadow-xl">
    <div>
      <p class="text-xl font-semibold my-2">{{sharedList ? sharedList.name : ''}}</p>
      <div class="flex space-x-2 text-gray-400 text-sm">
        <div class="w-5 h-5">
          <Icon name="person.svg" opacity="0.4"/>
        </div>
        <p class="font-light">{{ sharedList ? sharedList.sharedWith : ''}}</p> 
      </div>
      <div class="flex space-x-2 text-gray-400 text-sm my-3">
        <!-- svg  -->
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
        </svg>
        <p class="font-light">{{sharedList ? sharedList.sharedAt + ' ago' : ''}}</p> 
      </div>
      <div class="border-t-2 border-pink-500"></div>

      <div class="flex justify-between">
        <div class="my-2">
          <p class="font-semibold text-base mb-2">View</p>
          <div class="text-center">
            <button class="h-6 w-6 rounded-sm focus:outline-none transform active:translate-y-1" @click="onView"><Icon name="eye.svg" /></button>
          </div>
        </div>
        <div class="my-2">
          <p class="font-semibold text-base mb-2">Remove</p>
          <div class="text-center">
            <button class="h-6 w-6 rounded-sm focus:outline-none transform active:translate-y-1" @click="onDelete"><Icon name="trash-can.svg" /></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Icon from '@/components/Icon'

export default {
  props: {
    sharedList: Object
  },
  components: {
    Icon
  },
  methods: {
    onView () {
      this.$router.push({ name: 'share', params: { id: this.sharedList.id } })
    },
    onDelete () {
      this.$store.dispatch('deleteSharedList', this.sharedList.id)
    }
  }
}
</script>
